/* English Language */
export default {
  email: 'Email',
  password: 'Password',
  phone_number: 'Phone Number',
  email_required: 'Email is required',
  password_required: 'Password is required',
  email_valid: 'Email must be a valid email',
  password_regex_message: 'Password is not valid',
  password_min: 'Password must contain at least 6 characters',
  login: 'Continue to login',
  login_account: 'Login to your account.',
  forgot_password: 'Forgot password',
  account: 'Don’t have an account?',
  contact_support: 'Contact Support',
  send_recovery_link: 'send me a recovery link',
  register_email:
    'We will send you a password reset link on your registered email account.',
  enter_your_email: 'enter your email',
  reset_password: 'Reset Password',
  looks_new_account: 'Looks like a new account',
  create_new_password_text: 'Please create a new password',
  new_password: 'new password',
  confirm_password: 'confirm password',
  create_password: 'create password',
  password_not_match: "Password doesn't match",
  track_event: 'Track Event',
  resend_alert: 'Resend Alert',
  first_name: 'First Name',
  last_name: 'last Name',
  contact: 'Contact Number',
  organization: 'Organization',
  save_changes: 'Save Changes',
  assume_role: 'Assume Role',
  user_privilege: 'User Privilege',
  activities: 'Activities',
  apartment: 'Apartment/suite/office number',
  take_me_back: 'take me back',
  page_not_found: 'Page Not Found',
  page_not_found_text: 'The page that you are trying to reach does not exists.',
  create_alert: 'Create an Alert',
  creating_alert: 'Creating an Alert',
  state: 'State',
  city: 'City',
  sca_history: 'SCA history',
  send_sca_alerts: 'Send SCA Alerts',
  sending_sca_alerts: 'Sending SCA Alerts',

  firstname_req: 'First name is required',
  lastname_req: 'Last name is required',
  phone_req: 'Phone number is required',
  phone_valid: 'Please enter a valid phone number!',
  email_req: 'Email is required',
  role_req: 'Role is required',
  city_req: 'City is required',
  state_req: 'State is required',
  country_req: 'Country is required.',
  country: 'Country',
  zipcode: 'Zip Code (Optional)',
  enter_phone: 'Enter phone number',
  timezone: 'Timezone',
  timezone_req: 'Timezone is required',

  user_mgmt: 'User Management',
  user_mgmt_vol: 'User Management - Volunteers',
  user_mgmt_aed: 'User Management - AED Owners',
  user_mgmt_disp: 'User Management - Admins/Dispatcher',
  user_mgmt_juri: 'User Management - Jurisdiction',
  user_mgmt_aed_equipment: 'User Management - AED Equipment',
  vol_details: 'Volunteer Details - ',
  aed_details: 'AED Owner’s Details - ',
  admin_details: 'Admin Details - ',
  disp_details: 'Dispatcher Details - ',
  dispAdmin_detais: 'Dispatch admin Details - ',
  create_user: 'Create User',
  sca_events: 'SCA Events',
  ongoing_sca_events: 'Ongoing SCA Events',
  past_events: 'Past Events',
  event_details: 'Event Details',
  arriving_in: 'Arriving In',
  min: 'min',
  dashboard: 'Dashboard',
  no_data_yet: 'No Data yet',
  add_jurisdiction: 'Add Jurisdiction',
  new_jurisdiction: 'New Jurisdiction',
  create_jurisdiction: 'Create Jurisdiction',
  edit_jurisdiction: 'Save Jurisdiction',
  jurisdiction_details: 'Jurisdiction Details',
  label_of_the_jurisdiction: 'Label of the Jurisdiction',
  not_suporting_jurisdiction:
    'Currently we are not supporting this Country to create jurisdiction. Please contact administrator.',

  confirm_event_close: 'Are you sure you want to close this event ?',
  confirm_notify_again:
    'Are you sure you want to notify available volunteers again ?',
  confirm_delete_area:
    'Are you sure you want to delete selected Jurisdictions?',
  confirm_delete_aeds: 'Are you sure you want to delete selected AEDs?',
  confirm_impersonate_exit: 'Do you want to exit impersonating mode?',
  confirm_impersonate: 'Are you sure you want to enter impersonating mode as',
  confirm_delete_users: 'Are you sure you want to delete selected users?',
  confirm_delete: 'Are you sure you want to delete?',
  yes: 'Yes',
  no: 'No',
  something_wrong: 'Something went wrong!!!',
  select_state: 'Select states',
  select_cities: 'Select Cities',
  select_zipcode: 'Select Zipcodes',
  select_counties: 'Select Counties',
  export_pdf: 'Export PDF',
  share_pdf: 'Share PDF',
  resend_credentials: 'Resend Credentials',
  add_user: 'Add User',
  jurisdiction_req: 'Jurisdiction is required.',
  jurisdiction_name_req: 'Jurisdiction name is required.',
  county_req: 'Please select at least one County.',
  states_req: 'Please select at least one State.',
  cities_req: 'Please select at least one City.',
  zipcodes_req: 'Please select at least one Zipcode.',
  jurisdiction: 'Jurisdiction',
  select_jurisdiction: 'Select Jurisdiction',

  no_volunteers: 'No volunteers yet.',
  no_aed_owners: 'No AED Owners yet.',
  no_jurisdictions: 'No jurisdictions yet.',
  no_user: 'No Admin, Dispatcher or Dispatch Admin yet.',
  no_sca: 'No events yet.',
  no_aed_equipment: 'No AED Equipments yet.',
  no_activities: 'No activities yet.',
  create_sca_success: 'SCA event created successfully.',
  sca_expired: 'Event has been expired due to system time-out',
  search_location: 'Search for a Location....',
  latitude: 'Latitude',
  longitude: 'Longitude',
  enter_sca_address: 'Enter SCA event address',
  or_enter_latLong: 'Or Enter Latitude and Longitude',
  or_search_address: 'Or Search an Address',

  invalid_signature: 'Invalid signature!!',

  search: 'Search…',
  close: 'Close',

  internet_disconnected:
    'Internet has been disconnected. Please try troubleshooting the network settings.',
  internet_connected: "You're now connected!",

  start_adding: 'Start adding some, by clicking on the button below',
  generate_report: 'Generate Report',
  sca_attributes: 'SCA Attributes',
  volunteer_attributes: 'Volunteer Attributes',
  aed_equip_attributes: 'AED Equipment Attributes',
  select_certificates: 'Select Certificates',
  reports: 'Reports',
  sca_event_reports: 'SCA Event Reports',
  volunteer_reports: 'Volunteer Reports',
  aed_reports: 'AED Equipments',
  clear_filters: 'Clear Filters',
  valid_date: 'Please select valid Date Range!',
  select_date_from: 'Select Date From',
  select_date_to: 'Select Date To',
  sca_event_history: 'SCA Event History',
  sca_event_detail: 'SCA Event Detail',
  aed_not_imported:
    "Couldn't import following AEDs due to invalid data. Please enter valid data to import AED successfully!",
  no_volunteer_responded: 'No volunteer has responded',
  import_aed: 'Import AED',
  import_csv: 'Import CSV',
  edit_csv: 'Edit CSV Data',
  create_aed_owner: 'Create AED Owner',
  assign_aed: 'Assign AED',
  cancel: 'Cancel',
  confirm_unassign_Aed: 'Are you sure, you want to unassign this AED?',

  no_result: 'No records found!',
  share: 'Share',
  // type_emails: 'Type or paste email addresses and press `Enter`...',
  enter_email: 'Enter Email and press `Enter`',
  email_not_valid: 'is not a valid email address.',
  email_already_added: 'has already been added.',

  // event logs
  responded_event: 'responded to the event.',
  en_route_aed: 'en route to the AED.',
  en_route_sca_with_aed: 'en route to the SCA event with an AED.',
  aed_equipped: 'AED device equipped',
  en_route_sca_without_aed: 'en route to the SCA event without an AED.',
  aed_not_found: 'Did not find the AED.',
  en_route_sca: 'en route to the SCA Event.',
  arrived_sca: 'Arrived at SCA Location',
  ems_arrived: 'EMS Arrived',
  event_expire_auto: 'Event expired automatically.',
  exited_sca: 'Exited SCA',
  exited_sca_eta: 'was forced  to exit the event by the system due to higher ETA.',

  start_typing_initials: 'Start Typing Initials',
  select_all: 'Select All',

  version: 'Version',
  impersonating_as: 'Impersonating as',
  exit_impersonating: 'Exit Impersonating Mode',
  logout: 'Logout',

  upload: 'Upload',
  mandatory_fields:
    'Please make sure that the following fields are mandatory in the CSV document.',
  manufacturer: 'Manufacturer',
  model_number: 'Model Number',
  serial_number: 'Serial Number',
  installation_date: 'Installation Date',
  location_details: 'Location Details',
  pad_details: 'Pad Details',
  battery_details: 'Battery Details',

  aed_detail: 'AED Detail',
  aed_image: 'AED Image',
  street_view_image: 'Street View Image',
  street_image: 'Street Image',
  last_checked_on: 'Last Checked On',
  site_name: 'Site Name',
  floor_sub_location: 'Floor/Sub-location',
  location: 'Location',
  notes: 'Notes',
  availability: 'Availability',
  public: 'Public',
  private: 'Private',
  zoll_equipments: 'Zoll Medical Equipments',
  Others: 'Others',
  optional: 'Optional',

  volunteer_detail: 'Volunteer Detail',
  new_alert: 'New Alert',
  aeds_nearby: 'AEDs nearby',
  volunteers_nearby: 'Volunteers nearby',
  send_alert_to_all: 'Send alert to all active volunteers nearby SCA location.',

  geolocation_not_enabled:
    'Geolocation is not enabled. Please enable to use this feature.',
  geolocation_not_supported: 'Geolocation is not supported!',
  search_sca_area: 'Search SCA area, pin code…',
  active_sca: 'Active SCA events',
  no_active_case: 'No Active Cases',
  volunteers_accepted_event: 'Volunteers accepted the event',
  no_one_accepted_event: 'No one has accepted the SCA event yet.',
  ems_onsite: 'EMS Onsite',
  started_at: 'Started at',

  pass_change_succ: 'Password changed successfully!',
  something_wrong_try_later: 'Something went wrong, please try again later!',
  password_hint_text:
    'The password should be a minimum of 6 characters. It should have at least 1 symbol or a number and 1 capital letter.',
  back_to_login: 'Back To login',

  select_duration_sca: 'Select duration of SCA event',
  lt_5_mins: 'Less than 5 minutes',
  range5to10: '5 - 10 minutes',
  gt_10_mins: 'More than 10 minutes',
  event_response: 'Event Response',
  all: 'All',
  no_response: 'No Response',
  assisted: 'Assisted',
  not_assisted: 'Not Assisted',
  non_assisted: 'Non assisted',
  at: 'at',
  date_range: 'Date Range',
  role: 'Role',
  only_volunteer: 'Only volunteer',
  also_aed_owner: 'Also an AED owner',
  also_volunteer: 'Also a Volunteer',
  volunteer_certifications: 'Volunteer Certification Types',

  aed_equipment: 'AED Equipment',
  registered_aed: 'Registered AED',
  imported_aed: 'Imported AED',
  unassign_inactive_aed: 'Unassigned & Inactive',
  inactive: 'Inactive',
  active: 'Active',
  manufacturer_and_model: 'Manufacturer & Model',
  aed_owners: 'AED Owners',
  created_by_admin: 'Created by Admin',
  created_by_app: 'Created by Mobile Application',
  owner_name: 'AED Owner’s Name',
  organization_name: 'Organization Name',
  number_aed: 'Number of AED Devices',
  devices: 'Devices',
  more: 'more',
  new: 'New',
  users: 'Users',
  admins: 'Admins',
  dispatch_admins: 'Dispatch Admins',
  user_name: 'User’s Name',
  user_type: 'User Type',
  jurisdictions: 'Jurisdictions',
  jurisdiction_labels: 'Jurisdiction Labels',
  areas_covered: 'Areas Covered',
  counties: 'Counties',
  cities: 'Cities',
  volunteers: 'Volunteers',
  active_users: 'Active users',
  non_active_users: 'Non-active users',
  pending_approval: 'Pending Approval',
  volunteer_name: 'Volunteer’s Name',
  certifications: 'Certifications',
  address: 'Address',
  status: 'Status',

  today: 'Today',
  joined_as: 'joined AED link as an',
  added: 'Added',
  removed: 'Removed',
  added_jurisdiction: 'Added jurisdiction',
  removed_jurisdiction: 'Removed jurisdiction',
  activated_volunteer: 'Activated volunteer',
  de_activated_volunteer: 'De-activated volunteer',
  changed_role_from: 'Changed role from',
  to: 'to',
  last_week: 'Last Week',
  this_month: 'This Month',
  last_month: 'Last Month',
  last_2_month: 'Last 2 months',
  zoll_aed_equipment: 'Zoll AED Equipment',
  other_aed_equipment: 'Other Brands AED Equipment',
  breathing_restored: 'Breathing restored',
  breathing_not_restored: 'Breathing not restored',

  owner_details: 'Owner Details',
  customer_name: 'Customer Name',
  access: 'Access',
  timings: 'Timings',
  created_by: 'Created by',
  aed_devices: 'AED Devices',

  sca_events_served: 'SCA Events served',
  total_volunteers: 'Total Volunteers',
  total_aed_equipment: 'Total AED Equipment',
  quick_analysis: 'Quick Analysis',
  average_sca_completion: 'Average SCA completion time',
  minutes: 'Minutes',
  aed_equipments_registered: 'AED Equipments Registered',
  ratio_of_gender: 'Ratio of patient’s gender',
  ratio_of_age: 'Ratio of age of the patients',
  ratio_of_breathing: 'Ratio of patient’s whose breathing Was restored',
  average_between_sca_acceptance_first_shock:
    'The average time between SCA acceptance and the first shock delivered',

  sca_closed_succ: 'SCA Event closed successfully',
  sca_event_location: 'SCA Event Location',
  sca_dispatched_by: 'SCA Dispatched by',
  date_and_time: 'Date & Time',
  event_log: 'Event Log',
  survey_report: 'Survey Report',
  survey_from_volunteers: 'Survey Report from Volunteers',
  volunteers_notified: 'Volunteers notified',
  volunteer_address: 'Volunteer’s Address',

  admin: 'Admin',
  dispatcher: 'Dispatcher',
  dispatchAdmin: 'Dispatch Admin',
  mobile_app: 'Mobile Application',

  settings: 'Settings',
  preferred_language: 'Please select the preferred language from below',
  language: 'Language',

  time_zone_admin: 'This is your timezone set by the admin.',
  jurisdiction_info: 'Jurisdiction info',

  download_app: 'Download the app',
  volunters_save_lives: 'Organizing our Volunteers to save lives.',
  demo_description:
    'Our app helps volunteer track the Cardiac arrest victims and provide every possible support before the EMS arrives. Here is how we do it.',
  mobile_app_btn: 'Mobile App',
  web_dash_btn: 'Web Dashboard',
  expolore_app: 'Click here to see RescuerLink Demo',
  demo_login: 'Login',

  male: 'Male',
  female: 'Female',
  unknown: 'Unknown',
  years_0TO_5: '0 - 5 Years',
  years_5TO10: '5 - 10 Years',
  years_10TO15: '10 - 15 Years',
  years_15TO20: '15 - 20 Years',
  years_20TO25: '20 - 25 Years',
  years_25TO30: '25 - 30 Years',
  years_30TO35: '30 - 35 Years',
  years_35TO40: '35 - 40 Years',
  years_40TO45: '40 - 45 Years',
  years_45TO50: '45 - 50 Years',
  years_50TO55: '50 - 55 Years',
  years_55TO60: '55 - 60 Years',
  years_60TO65: '60 - 65 Years',
  years_65TO70: '65 - 70 Years',
  years_70TO75: '70 - 75 Years',
  years_75TO80: '75 - 80 Years',
  years_80TO85: '80 - 85 Years',
  years_85TO90: '85 - 90 Years',
  GT_90_years: '90+ Years',

  gender_question: 'What was the patient’s gender?',
  age_question: 'Estimated age of the patient?',
  cpr_given: 'Was CPR given prior to 911 arrival?',
  cpr_given_time: 'Estimated time of CPR delivery?',
  pads_placed: 'Were the AED Pads placed on the patient?',
  pads_placed_time: 'Estimated time when pads were placed?',
  aed_turned_on: 'Was AED turned on?',
  aed_turned_on_time: 'Estimated time, when it was turned on?',
  patient_shocked: 'Did the AED ever shock the patient?',
  patient_shocked_time: 'Estimated time & number of shocks delivered?',
  number_of_shocks: 'Enter Number of Shocks Delivered',
  breathing_restored_question: 'Did the patient have their breathing restored.',
  breathing_restored_time: 'Estimated time, when breathing was restored?',
  transported_hospital: 'Was the patient transported to the hospital?',
  other_comments:
    'Other comments/concerns not referenced that may be useful for the medical Doctor?',
  restart_demo: 'Restart Demo',
  copy_auth_token: 'Copy Auth Token',
  copied_to_clipboard: 'Copied to clipboard!',
};
