// Malay Language
export default {
  email: 'e-mel',
  password: 'kata laluan',
  phone_number: 'Nombor telefon',
  email_required: 'E-mel diperlukan',
  password_required: 'kata laluan diperlukan',
  email_valid: 'E-mel mesti mengandungi e-mel yang sah',
  password_regex_message: 'Kata laluan tidak sah',
  password_min: 'Kata laluan mesti mengandungi sekurang-kurangnya 6 aksara',
  login: 'Terus login',
  login_account: 'Log masuk ke akaun anda.',
  forgot_password: 'Lupa kata laluan',
  account: 'Tiada akaun?',
  contact_support: 'hubungi Sokongan',
  send_recovery_link: 'menghantar saya link pemulihan',
  register_email:
    'Kami akan menghantar penetapan semula kata laluan akaun e-mel berdaftar anda.',
  enter_your_email: 'masukkan emel anda',
  reset_password: 'Menetapkan semula kata laluan',
  looks_new_account: 'Nampaknya akaun baru',
  create_new_password_text: 'Sila buat kata laluan baru',
  new_password: 'kata laluan baharu',
  confirm_password: 'kata laluan confirm',
  create_password: 'bina kata laluan',
  password_not_match: 'Kata laluan tidak sepadan',
  track_event: 'Track Event',
  resend_alert: 'Hantar semula Alert',
  first_name: 'Nama pertama',
  last_name: 'nama terakhir',
  contact: 'Nombor telefon',
  organization: 'organisasi',
  save_changes: 'Simpan Perubahan',
  assume_role: 'Andaikan Peranan',
  user_privilege: 'Privilege pengguna',
  activities: 'aktiviti',
  apartment: 'Apartment / suite nombor / pejabat',
  take_me_back: 'membawa aku kembali',
  page_not_found: 'Laman tidak dijumpai',
  page_not_found_text: 'Halaman yang anda cuba untuk mencapai tidak wujud.',
  create_alert: 'Buat Alert',
  creating_alert: 'Mewujudkan Alert',
  state: 'Negeri',
  city: 'City',
  sca_history: 'sejarah SCA',
  send_sca_alerts: 'Menghantar Peringatan SCA',
  sending_sca_alerts: 'Hantar Peringatan SCA',
  firstname_req: 'nama pertama diperlukan',
  lastname_req: 'Nama akhir diperlukan',
  phone_req: 'Nombor telefon diperlukan',
  phone_valid: 'Sila masukkan nombor telefon yang sah!',
  email_req: 'E-mel diperlukan',
  role_req: 'Peranan diperlukan',
  city_req: 'Bandar diperlukan',
  state_req: 'Negeri diperlukan',
  country_req: 'Negara ini diperlukan.',
  country: 'negara',
  zipcode: 'Zip Code (Pilihan)',
  enter_phone: 'Masukkan nombor telefon',
  timezone: 'Zon masa',
  timezone_req: 'Zon waktu diperlukan',
  user_mgmt: 'Pengurusan pengguna',
  user_mgmt_vol: 'Pengurusan Pengguna - Sukarelawan',
  user_mgmt_aed: 'Pengurusan Pengguna - Pemilik AED',
  user_mgmt_disp: 'Pengurusan Pengguna - Admin / Dispatcher',
  user_mgmt_juri: 'Pengurusan Pengguna - Bidang kuasa',
  user_mgmt_aed_equipment: 'Pengurusan Pengguna - AED Equipment',
  vol_details: 'Maklumat Sukarelawan -',
  aed_details: 'AED Pemilik Butiran -',
  admin_details: 'Admin Butiran -',
  '': '',
  disp_details: 'Yg Butiran -',
  dispAdmin_detais: 'Dispatch admin Butiran -',
  create_user: 'Buat Pengguna',
  sca_events: 'SCA Peristiwa',
  ongoing_sca_events: 'Berterusan SCA Peristiwa',
  past_events: 'Peristiwa lalu',
  event_details: 'event Details',
  dashboard: 'Dashboard',
  no_data_yet: 'No Data lagi',
  add_jurisdiction: 'Tambah Bidang Kuasa',
  new_jurisdiction: 'Bidang kuasa baru',
  create_jurisdiction: 'Buat Bidang Kuasa',
  edit_jurisdiction: 'Simpan Bidang Kuasa',
  jurisdiction_details: 'bidang kuasa Butiran',
  label_of_the_jurisdiction: 'Labelkan Bidang Kuasa yang',
  not_suporting_jurisdiction:
    'Pada masa ini kita tidak menyokong Negara ini untuk membuat bidang kuasa. Sila hubungi pentadbir.',
  confirm_event_close: 'Adakah anda pasti anda mahu menutup acara ini?',
  confirm_notify_again:
    'Adakah anda pasti anda ingin memberitahu sukarelawan ada lagi?',
  confirm_delete_area:
    'Adakah anda pasti anda mahu memadam Perundangan dipilih?',
  confirm_delete_aeds: 'Adakah anda pasti anda mahu memadam AEDs dipilih?',
  confirm_impersonate_exit:
    'Adakah anda ingin untuk keluar daripada mod menyamar?',
  confirm_impersonate:
    'Adakah anda pasti anda mahu memasuki mod menyamar sebagai',
  confirm_delete_users:
    'Adakah anda pasti anda mahu memadam pengguna yang dipilih?',
  confirm_delete: 'Adakah anda pasti ingin memadam?',
  yes: 'Ya',
  no: 'Tiada',
  something_wrong: 'Sesuatu telah berlaku !!!',
  select_state: 'pilih negeri',
  select_cities: 'Pilih Bandar',
  select_zipcode: 'Pilih Zipcodes',
  select_counties: 'Pilih Counties',
  export_pdf: 'eksport PDF',
  share_pdf: 'Kongsi PDF',
  resend_credentials: 'Bukti kelayakan Hantar semula',
  add_user: 'Tambah Pengguna',
  jurisdiction_req: 'Bidang kuasa diperlukan.',
  jurisdiction_name_req: 'nama bidang kuasa diperlukan.',
  county_req: 'Sila pilih sekurang-kurangnya satu County.',
  states_req: 'Sila pilih sekurang-kurangnya satu Negara.',
  cities_req: 'Sila pilih sekurang-kurangnya satu City.',
  zipcodes_req: 'Sila pilih sekurang-kurangnya satu poskod.',
  jurisdiction: 'bidang kuasa',
  select_jurisdiction: 'Pilih Bidang Kuasa',
  no_volunteers: 'Tidak ada sesiapa lagi.',
  no_aed_owners: 'No Owners AED lagi.',
  no_jurisdictions: 'Tiada bidang kuasa lagi.',
  no_user: 'No Admin, Penghantar atau Dispatch Admin lagi.',
  no_sca: 'Tiada aktiviti lagi.',
  no_aed_equipment: 'No AED Equipments lagi.',
  no_activities: 'Tiada aktiviti lagi.',
  create_sca_success: 'acara SCA berjaya dibuat.',
  sca_expired: 'Acara telah tamat tempoh kerana sistem time-out',
  search_location: 'Cari lokasi ....',
  latitude: 'Latitud',
  longitude: 'longitud',
  enter_sca_address: 'Masukkan SCA alamat peristiwa',
  or_enter_latLong: 'Atau Enter Latitud dan Longitud',
  or_search_address: 'Cari sebuah Alamat',
  invalid_signature: 'tandatangan tidak sah !!',
  search: 'Cari ...',
  close: 'Tutup',
  internet_disconnected:
    'Internet telah diputuskan. Sila cuba penyelesaian masalah tetapan rangkaian.',
  internet_connected: 'Anda telah disambungkan!',
  start_adding: 'Mula menambah beberapa, dengan klik pada butang di bawah',
  generate_report: 'menjana Laporan',
  sca_attributes: 'Atribut SCA',
  volunteer_attributes: 'Atribut Volunteer',
  aed_equip_attributes: 'Sifat-sifat AED Equipment',
  select_certificates: 'pilih Sijil',
  reports: 'laporan',
  sca_event_reports: 'SCA Laporan Event',
  volunteer_reports: 'Laporan Volunteer',
  aed_reports: 'AED Equipments',
  clear_filters: 'Penapis jelas',
  valid_date: 'Sila pilih sah Tarikh Range!',
  select_date_from: 'Pilih Tarikh Dari',
  select_date_to: 'Pilih Tarikh Untuk',
  sca_event_history: 'SCA Sejarah Acara',
  sca_event_detail: 'Detail SCA Event',
  aed_not_imported:
    'Tidak dapat mengimport AEDs berikut kerana data tidak sah. Sila masukkan data yang sah untuk mengimport AED berjaya!',
  no_volunteer_responded: 'No sukarelawan telah memberi respons',
  import_aed: 'import AED',
  import_csv: 'import CSV',
  edit_csv: 'Edit CSV Data',
  create_aed_owner: 'Buat AED Owner',
  assign_aed: 'Berikan AED',
  cancel: 'Batal',
  confirm_unassign_Aed:
    'Adakah anda pasti, anda mahu untuk menarik balik AED ini?',
  no_result: 'Tiada rekod dijumpai!',
  share: 'Kongsi',
  enter_email: 'Masukkan E-mel dan tekan `Enter`',
  email_not_valid: 'bukan alamat e-mel yang sah.',
  email_already_added: 'telah ditambah.',
  responded_event: 'respons kepada acara itu.',
  en_route_aed: 'dalam perjalanan ke AED.',
  en_route_sca_with_aed: 'dalam perjalanan ke acara SCA dengan AED.',
  aed_equipped: 'peranti AED dilengkapi',
  en_route_sca_without_aed: 'dalam perjalanan ke acara SCA tanpa AED.',
  aed_not_found: 'Tidak jumpa AED.',
  en_route_sca: 'dalam perjalanan ke acara SCA.',
  arrived_sca: 'Tiba di SCA Lokasi',
  ems_arrived: 'EMS Tiba',
  event_expire_auto: 'Acara tamat secara automatik.',
  exited_sca: 'SCA keluar',
  exited_sca_eta:'terpaksa keluar dari majlis itu oleh sistem kerana ETA lebih tinggi',
  start_typing_initials: 'Mula Menaip Permulaan',
  select_all: 'Pilih semua',
  version: 'versi',
  impersonating_as: 'menyamar sebagai',
  exit_impersonating: 'Keluar Mod menyamar',
  logout: 'Log keluar',
  upload: 'muat naik',
  mandatory_fields:
    'Sila pastikan bahawa bidang-bidang berikut adalah mandatori dalam dokumen CSV.',
  manufacturer: 'pengeluar',
  model_number: 'Nombor model',
  serial_number: 'Nombor siri',
  installation_date: 'Tarikh pemasangan',
  location_details: 'Peta & lokasi',
  pad_details: 'pad Maklumat',
  battery_details: 'Maklumat bateri',
  aed_detail: 'Detail AED',
  aed_image: 'AED Image',
  street_view_image: 'View Street Image',
  street_image: 'Street Image',
  last_checked_on: 'Lepas Diperiksa Dalam',
  site_name: 'Nama laman',
  floor_sub_location: 'Tingkat / Sub-lokasi',
  location: 'lokasi',
  notes: 'Nota',
  availability: 'ketersediaan',
  public: 'awam',
  private: 'swasta',
  zoll_equipments: 'Zoll Peralatan Perubatan',
  Others: 'lain-lain',
  optional: 'pilihan',
  volunteer_detail: 'Detail Volunteer',
  new_alert: 'New Alert',
  aeds_nearby: 'AEDs berdekatan',
  volunteers_nearby: 'Sukarelawan berdekatan',
  send_alert_to_all:
    'Hantar amaran kepada semua sukarelawan yang aktif berdekatan dengan lokasi SCA.',
  geolocation_not_enabled:
    'Geolokasi tidak didayakan. Tolong membenarkan untuk menggunakan ciri ini.',
  geolocation_not_supported: 'Geolokasi tidak disokong!',
  search_sca_area: 'Mencari kawasan SCA, kod pin ...',
  active_sca: 'aktiviti SCA aktif',
  no_active_case: 'Tiada kes Aktif',
  volunteers_accepted_event: 'Sukarelawan diterima acara',
  no_one_accepted_event: 'Tiada siapa yang telah memperoleh acara SCA lagi.',
  ems_onsite: 'EMS di tapak',
  started_at: 'bermula pada',
  pass_change_succ: 'Kata laluan ditukar dengan jayanya!',
  something_wrong_try_later:
    'Ada sesuatu yang tidak salah, sila cuba lagi kemudian!',
  password_hint_text:
    'kata laluan yang perlu ada sekurang-kurangnya 6 aksara. Ia harus mempunyai sekurang-kurangnya 1 simbol atau nombor dan huruf besar 1.',
  back_to_login: 'Back To login',
  select_duration_sca: 'Pilih tempoh acara SCA',
  lt_5_mins: 'Kurang dari 5 minit',
  range5to10: '5 - 10 minit',
  gt_10_mins: 'Lebih daripada 10 minit',
  event_response: 'acara Response',
  all: 'Semua',
  no_response: 'Tiada jawapan',
  assisted: 'dibantu',
  not_assisted: 'tidak dibantu',
  non_assisted: 'bukan dibantu',
  at: 'di',
  date_range: 'Julat tarikh',
  role: 'peranan',
  only_volunteer: 'hanya sukarelawan',
  also_aed_owner: 'Juga merupakan pemilik AED',
  also_volunteer: 'Juga Volunteer',
  volunteer_certifications: 'Volunteer Jenis Sijil',
  aed_equipment: 'AED Equipment',
  registered_aed: 'AED berdaftar',
  imported_aed: 'Imported AED',
  manufacturer_and_model: 'Pengeluar & Model',
  aed_owners: 'Pemilik AED',
  created_by_admin: 'Dicipta oleh Admin',
  created_by_app: 'Dicipta oleh Aplikasi Mudah Alih',
  owner_name: 'Nama AED Pemilik',
  organization_name: 'Nama Pertubuhan',
  number_aed: 'Bilangan Peranti AED',
  devices: 'Peranti',
  more: 'lebih',
  new: 'Baru',
  users: 'pengguna',
  admins: 'admin',
  dispatcher: 'yg',
  dispatch_admins: 'Dispatch Admin',
  user_name: 'Nama pengguna',
  user_type: 'Jenis pengguna',
  jurisdictions: 'bidang kuasa',
  jurisdiction_labels: 'Labels bidang kuasa',
  areas_covered: 'kawasan yang dilindungi',
  counties: 'daerah-daerah',
  cities: 'bandar-bandar',
  volunteers: 'Sukarelawan',
  active_users: 'pengguna aktif',
  non_active_users: 'pengguna yang tidak aktif',
  pending_approval: 'Kelulusan yang belum selesai',
  volunteer_name: 'Nama sukarelawan yang',
  certifications: 'Persijilan',
  address: 'alamat',
  status: 'status',
  today: 'hari ini',
  joined_as: 'link AED menyertai sebagai',
  added: 'Ditambah',
  removed: 'dikeluarkan',
  added_jurisdiction: 'bidang kuasa ditambah',
  removed_jurisdiction: 'bidang kuasa dikeluarkan',
  activated_volunteer: 'sukarelawan diaktifkan',
  de_activated_volunteer: 'sukarelawan de-diaktifkan',
  changed_role_from: 'peranan berubah daripada',
  to: 'kepada',
  last_week: 'Minggu lepas',
  this_month: 'Bulan ini',
  last_month: 'Bulan lepas',
  last_2_month: '2 bulan yang lalu',
  zoll_aed_equipment: 'Zoll AED Equipment',
  other_aed_equipment: 'Jenama lain AED Equipment',
  breathing_restored: 'pernafasan dipulihkan',
  breathing_not_restored: 'Pernafasan tidak dipulihkan',
  owner_details: 'Owner Maklumat',
  customer_name: 'Nama Pelanggan',
  access: 'Access',
  timings: 'pengaturan masa',
  created_by: 'Dicipta oleh',
  aed_devices: 'Peranti AED',
  sca_events_served: 'SCA Peristiwa berkhidmat',
  total_volunteers: 'Jumlah Sukarelawan',
  total_aed_equipment: 'Jumlah AED Equipment',
  quick_analysis: 'Analisis Pantas',
  average_sca_completion: 'Purata SCA masa siap',
  minutes: 'minit',
  aed_equipments_registered: 'AED Equipments Berdaftar',
  ratio_of_gender: 'Nisbah jantina pesakit',
  ratio_of_age: 'Nisbah umur pesakit',
  ratio_of_breathing: 'Nisbah pesakit yang yang bernafas Telah dipulihkan',
  average_between_sca_acceptance_first_shock:
    'Purata masa antara penerimaan SCA dan kejutan pertama dihantar',
  sca_closed_succ: 'SCA Event ditutup berjaya',
  sca_event_location: 'SCA Lokasi Acara',
  sca_dispatched_by: 'SCA Dihantar oleh',
  date_and_time: 'Masa tarikh',
  event_log: 'event Log',
  survey_report: 'Laporan kaji selidik',
  survey_from_volunteers: 'Laporan kajian daripada Sukarelawan',
  volunteers_notified: 'Sukarelawan diberitahu',
  volunteer_address: 'Volunteer Ucapan',
  admin: 'Admin',
  dispatchAdmin: 'Dispatch Admin',
  mobile_app: 'Aplikasi Mudah alih',
  settings: 'tetapan',
  preferred_language: 'Sila pilih bahasa pilihan dari bawah',
  language: 'bahasa',
  time_zone_admin: 'Ini adalah zon waktu anda ditetapkan oleh admin.',
  jurisdiction_info: 'maklumat bidang kuasa',
  arriving_in: 'tiba Dalam',
  min: 'min',
  download_app: 'Memuat turun aplikasi',
  volunters_save_lives:
    'Menganjurkan Sukarelawan kami untuk menyelamatkan nyawa.',
  demo_description:
    'app kami membantu sukarelawan mengesan mangsa Serangan jantung dan memberi sokongan yang tidak mungkin sebelum EMS tiba. Di sini ialah bagaimana kita melakukannya.',
  mobile_app_btn: 'Mobile App',
  web_dash_btn: 'web Dashboard',
  expolore_app: 'Klik di sini untuk melihat RescuerLink Demo',
  demo_login: 'Log masuk',
  male: 'lelaki',
  female: 'perempuan',
  unknown: 'tidak diketahui',
  years_0TO_5: '0 - 5 Tahun',
  years_5TO10: '5 - 10 Tahun',
  years_10TO15: '10 - 15 Tahun',
  years_15TO20: '15 - 20 Tahun',
  years_20TO25: '20 - 25 Tahun',
  years_25TO30: '25 - 30 Tahun',
  years_30TO35: '30 - 35 Tahun',
  years_35TO40: '35 - 40 Tahun',
  years_40TO45: '40 - 45 Tahun',
  years_45TO50: '45-50 Tahun',
  years_50TO55: '50-55 Tahun',
  years_55TO60: '55 - 60 Tahun',
  years_60TO65: '60 - 65 Tahun',
  years_65TO70: '65 - 70 Tahun',
  years_70TO75: '70 - 75 Tahun',
  years_75TO80: '75 - 80 Tahun',
  years_80TO85: '80-85 Tahun',
  years_85TO90: '85-90 Tahun',
  GT_90_years: '90+ Tahun',
  gender_question: 'Apa yang jantina pesakit?',
  age_question: 'Anggaran usia pesakit?',
  cpr_given: 'Telah CPR diberikan sebelum 911 ketibaan?',
  cpr_given_time: 'Anggaran masa penghantaran CPR?',
  pads_placed: 'Telah AED Pad diletakkan pada pesakit?',
  pads_placed_time: 'Anggaran masa apabila pad diletakkan?',
  aed_turned_on: 'Telah AED dihidupkan?',
  aed_turned_on_time: 'Anggaran masa, apabila ia dihidupkan?',
  patient_shocked: 'Adakah AED pernah kejutan pesakit?',
  patient_shocked_time: 'Anggaran masa & beberapa kejutan dihantar?',
  number_of_shocks: 'Masukkan Bilangan Kejutan Dihantar',
  breathing_restored_question:
    'Adakah pesakit mempunyai pernafasan mereka dipulihkan.',
  breathing_restored_time: 'Anggaran masa, apabila pernafasan dipulihkan?',
  transported_hospital: 'Adakah pesakit dibawa ke hospital?',
  other_comments:
    'Komen-komen lain / kebimbangan tidak dirujuk yang mungkin berguna untuk Doktor perubatan?',
  restart_demo: 'Mulakan semula Demo',
  copy_auth_token: 'Salinan Auth Token',
  copied_to_clipboard: 'Disalin ke clipboard!',
};
